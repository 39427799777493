import { AdminOrAllPermissions } from "@/includes/types/Enums";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { ReactionEmojiEnum } from "@/includes/logic/Modules/modules/modules/ReputationMessageHandlerModule/types";
import RanksHandlerModule from "@/includes/logic/Modules/modules/modules/RanksHandlerModule/RanksHandlerModule";
import { ToxicFilterModeEnum } from "@/includes/logic/Modules/modules/modules/ToxicFilterHandlerModule/types";

import { SelectOption } from "piramis-base-components/src/logic/types";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class SelectOptionsMixin extends Vue {
  banTypesUpperCaseOptions(prefix?: string): Array<SelectOption> {
    return [
      {
        label: this.$t("param_do_not_punish").toString(),
        value: "None",
      },
      {
        label: this.$t(`${ prefix ?? '' }param_remove_from_chat_for_while`).toString(),
        value: "Ban",
      },
      {
        label: this.$t("silent_mode").toString(),
        value: "Mute",
      },
      {
        label: this.$t(`${ prefix ?? '' }param_remove_from_chat_forever`).toString(),
        value: "Kick",
      }
    ]
  }

  defaultBanTypesLowerCaseOptions(prefix?: string): Array<SelectOption> {
    return [
      {
        label: this.$t("param_do_not_punish").toString(),
        value: "none",
      },
      {
        label: this.$t(`${ prefix ?? '' }param_remove_from_chat_for_while`).toString(),
        value: "ban",
      },
      {
        label: this.$t("silent_mode").toString(),
        value: "mute",
      },
      {
        label: this.$t(`${ prefix ?? '' }param_remove_from_chat_forever`).toString(),
        value: "kick",
      }
    ];
  }

  muteMediaOptions(prefix?: string): Array<SelectOption> {
    return [
      ...this.defaultBanTypesLowerCaseOptions(prefix),
      {
        label: this.$t('mute_media').toString(),
        value: 'mute_media'
      } ];
  }

  get commandPermissionsOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t("command_permissions_list_none"),
        value: AdminOrAllPermissions.None,
      },
      {
        label: this.$t("command_permissions_list_admins"),
        value: AdminOrAllPermissions.Admins,
      },
      {
        label: this.$t("command_permissions_list_all"),
        value: AdminOrAllPermissions.All,
      }
    ]
  }

  get cryptoPermissionsOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t("crypto_permissions_list_none"),
        value: AdminOrAllPermissions.None,
      },
      {
        label: this.$t("crypto_permissions_list_admins"),
        value: AdminOrAllPermissions.Admins,
      },
      {
        label: this.$t("crypto_permissions_list_all"),
        value: AdminOrAllPermissions.All,
      }
    ]
  }

  get exchangeProvidersOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t("exchange_provider_bitfinex"),
        value: "BITFINEX",
      },
      {
        label: this.$t("exchange_provider_coingecko"),
        value: "COINGECKO"
      }
    ]
  }

  commandsSelectOption: Array<SelectOption> = [
    { label: 'dmute', value: 'dmute' },
    { label: 'dmute_media', value: 'dmute_media' },
    { label: 'dmute_inline', value: 'dmute_inline' },
    { label: 'dmute_invite', value: 'dmute_invite' },
    { label: 'dmute_pools', value: 'dmute_pools' },
    { label: 'mute', value: 'mute' },
    { label: 'mute_media', value: 'mute_media' },
    { label: 'mute_inline', value: 'mute_inline' },
    { label: 'mute_invite', value: 'mute_invite' },
    { label: 'mute_pools', value: 'mute_pools' },
    { label: 'ban', value: 'ban' },
    { label: 'kick', value: 'kick' },
    { label: 'un', value: 'un' },
    { label: 'unmute', value: 'unmute' },
    { label: 'unban', value: 'unban' },
    { label: 'warn', value: 'warn' },
    { label: 'unwarn', value: 'unwarn' },
    { label: 'report', value: 'report' },
    { label: 'rules', value: 'rules' },
    { label: 'voteban', value: 'voteban' },
    { label: 'stat', value: 'stat' },
    { label: 'topmsg', value: 'topmsg' },
    { label: 'bottommsg', value: 'bottommsg' },
    { label: 'toprep', value: 'toprep' },
    { label: 'bottomrep', value: 'bottomrep' },
    { label: 'topxp', value: 'topxp' },
    { label: 'bottomxp', value: 'bottomxp' },
    { label: 'topap', value: 'topap' },
    { label: 'bottomap', value: 'bottomap' },
    { label: 'topref', value: 'topref' },
    { label: 'bottomref', value: 'bottomref' },
    { label: 'toprank', value: 'toprank' },
    { label: 'bottomrank', value: 'bottomrank' },
    { label: 'lasttoprep', value: 'lasttoprep' },
    { label: 'lastbottomrep', value: 'lastbottomrep' },
    { label: 'lasttopxp', value: 'lasttopxp' },
    { label: 'lastbottomxp', value: 'lastbottomxp' },
    { label: 'lasttopap', value: 'lasttopap' },
    { label: 'lastbottomap', value: 'lastbottomap' },
    { label: 'lasttopmsg', value: 'lasttopmsg' },
    { label: 'lastbottommsg', value: 'lastbottommsg' },
    { label: 'filter_stick_pack', value: 'filter_stick_pack' },
    { label: 'filter_stop_word', value: 'filter_stop_word' },
    { label: 'pin', value: 'pin' },
    { label: 'msg', value: 'msg' },
    { label: 'dm', value: 'dm' },
    { label: 'purge', value: 'purge' },
    { label: 'update_keyboard', value: 'update_keyboard' },
    { label: 'me', value: 'me' },
    { label: 'status', value: 'status' },
    { label: 'statusmsg', value: 'statusmsg' },
    { label: 'purge_user', value: 'purge_user' },
    { label: 'chat', value: 'chat' },
    { label: 'reload_admins', value: 'reload_admins' },
    { label: 'reload', value: 'reload' },
    { label: 'update', value: 'update' },
    { label: 'tc', value: 'tc' },
    { label: 'sup_get_raw_user_info', value: 'sup_get_raw_user_info' },
    { label: 'sup_setprop_user', value: 'sup_setprop_user' },
    { label: 'sup_setprop_bot', value: 'sup_setprop_bot' },
    { label: 'sup_setprop_chat', value: 'sup_setprop_chat' },
    { label: 'sup_delprop_user', value: 'sup_delprop_user' },
    { label: 'sup_delprop_bot', value: 'sup_delprop_bot' },
    { label: 'sup_delprop_chat', value: 'sup_delprop_chat' },
    { label: 'sup_rewrite_parent', value: 'sup_rewrite_parent' },
    { label: 'sup_add_anti_user_reputation', value: 'sup_add_anti_user_reputation' },
    { label: 'sup_add_user_reputation', value: 'sup_add_user_reputation' },
    { label: 'sup_add_user_xp', value: 'sup_add_user_xp' },
    { label: 'sup_add_user_ap', value: 'sup_add_user_ap' },
    { label: 'sup_set_user_rank', value: 'sup_set_user_rank' },
    { label: 'advertising_today', value: 'advertising_today' },
    { label: 'PINGping', value: 'PINGping' },
    { label: 'rmkb', value: 'rmkb' },
    { label: 'repin', value: 'repin' },
    { label: 'unpin', value: 'unpin' },
    { label: 'unpinall', value: 'unpinall' },
    { label: 'unrepin', value: 'unrepin' },
    { label: 'testlog', value: 'testlog' },
    { label: 'represet', value: 'represet' },
    { label: 'trcareset', value: 'trcareset' },
    { label: 'trwareset', value: 'trwareset' },
    { label: 'dellog', value: 'dellog' },
    { label: 'addwl', value: 'addwl' },
    { label: 'delwl', value: 'delwl' },
    { label: 'botaddadmin', value: 'botaddadmin' },
    { label: 'botdeladmin', value: 'botdeladmin' },
    { label: 'ro', value: 'ro' },
    { label: 'roall', value: 'roall' },
    { label: 'testmode', value: 'testmode' },
    { label: 'testmodeoff', value: 'testmodeoff' },
    { label: 'kickall', value: 'kickall' },
    { label: 'kickallnew', value: 'kickallnew' },
    { label: 'kickalloff', value: 'kickalloff' },
    { label: 'siteaddadmin', value: 'siteaddadmin' },
    { label: 'sitedeladmin', value: 'sitedeladmin' },
    { label: 'reslim', value: 'reslim' },
    { label: 'setconfig', value: 'setconfig' },
    { label: 'revokelink', value: 'revokelink' },
    { label: 'donate', value: 'donate' },
    { label: 'resetrep', value: 'resetrep' },
    { label: 'resetfilters', value: 'resetfilters' },
    { label: 'resetap', value: 'resetap' },
    { label: 'resetxp', value: 'resetxp' },
    { label: 'resetrank', value: 'resetrank' },
    { label: 'resetallrep', value: 'resetallrep' },
    { label: 'resetallfilters', value: 'resetallfilters' },
    { label: 'resetallap', value: 'resetallap' },
    { label: 'resetallxp', value: 'resetallxp' },
    { label: 'resetallrank', value: 'resetallrank' },
    { label: '-wtf', value: '-wtf' },
    { label: '-addfilter', value: '-addfilter' },
    { label: '-resetfilter', value: '-resetfilter' },
    { label: '-delfilter', value: '-delfilter' },
    { label: 'ccm', value: 'ccm' },
    { label: 'cct', value: 'cct' },
    { label: 'btc', value: 'btc' },
  ]

  sCommandsSelectOption: Array<SelectOption> = [
    { label: 'sdmute', value: 'sdmute' },
    { label: 'sdmute_media', value: 'sdmute_media' },
    { label: 'sdmute_inline', value: 'sdmute_inline' },
    { label: 'sdmute_invite', value: 'sdmute_invite' },
    { label: 'sdmute_pools', value: 'sdmute_pools' },
    { label: 'smute', value: 'smute' },
    { label: 'smute_media', value: 'smute_media' },
    { label: 'smute_inline', value: 'smute_inline' },
    { label: 'smute_invite', value: 'smute_invite' },
    { label: 'smute_pools', value: 'smute_pools' },
    { label: 'sban', value: 'sban' },
    { label: 'skick', value: 'skick' },
    { label: 'sun', value: 'sun' },
    { label: 'sunmute', value: 'sunmute' },
    { label: 'sunban', value: 'sunban' },
    { label: 'swarn', value: 'swarn' },
    { label: 'sreport', value: 'sreport' },
    { label: 'srules', value: 'srules' },
    { label: 'svoteban', value: 'svoteban' },
    { label: 'sstat', value: 'sstat' },
    { label: 'stopmsg', value: 'stopmsg' },
    { label: 'sbottommsg', value: 'sbottommsg' },
    { label: 'stoprep', value: 'stoprep' },
    { label: 'sbottomrep', value: 'sbottomrep' },
    { label: 'stopxp', value: 'stopxp' },
    { label: 'sbottomxp', value: 'sbottomxp' },
    { label: 'stopap', value: 'stopap' },
    { label: 'sbottomap', value: 'sbottomap' },
    { label: 'stopref', value: 'stopref' },
    { label: 'sbottomref', value: 'sbottomref' },
    { label: 'stoprank', value: 'stoprank' },
    { label: 'sbottomrank', value: 'sbottomrank' },
    { label: 'slasttoprep', value: 'slasttoprep' },
    { label: 'slastbottomrep', value: 'slastbottomrep' },
    { label: 'slasttopxp', value: 'slasttopxp' },
    { label: 'slastbottomxp', value: 'slastbottomxp' },
    { label: 'slasttopap', value: 'slasttopap' },
    { label: 'slastbottomap', value: 'slastbottomap' },
    { label: 'slasttopmsg', value: 'slasttopmsg' },
    { label: 'slastbottommsg', value: 'slastbottommsg' },
    { label: 'sfilter_stick_pack', value: 'sfilter_stick_pack' },
    { label: 'sfilter_stop_word', value: 'sfilter_stop_word' },
    { label: 'spin', value: 'spin' },
    { label: 'smsg', value: 'smsg' },
    { label: 'sdm', value: 'sdm' },
    { label: 'spurge', value: 'spurge' },
    { label: 'supdate_keyboard', value: 'supdate_keyboard' },
    { label: 'sme', value: 'sme' },
    { label: 'sstatus', value: 'sstatus' },
    { label: 'sstatusmsg', value: 'sstatusmsg' },
    { label: 'spurge_user', value: 'spurge_user' },
    { label: 'schat', value: 'schat' },
    { label: 'sreload_admins', value: 'sreload_admins' },
    { label: 'sreload', value: 'sreload' },
    { label: 'supdate', value: 'supdate' },
    { label: 'stc', value: 'stc' },
    { label: 'ssup_get_raw_user_info', value: 'ssup_get_raw_user_info' },
    { label: 'ssup_setprop_user', value: 'ssup_setprop_user' },
    { label: 'ssup_setprop_bot', value: 'ssup_setprop_bot' },
    { label: 'ssup_setprop_chat', value: 'ssup_setprop_chat' },
    { label: 'ssup_delprop_user', value: 'ssup_delprop_user' },
    { label: 'ssup_delprop_bot', value: 'ssup_delprop_bot' },
    { label: 'ssup_delprop_chat', value: 'ssup_delprop_chat' },
    { label: 'ssup_rewrite_parent', value: 'ssup_rewrite_parent' },
    { label: 'ssup_add_anti_user_reputation', value: 'ssup_add_anti_user_reputation' },
    { label: 'ssup_add_user_reputation', value: 'ssup_add_user_reputation' },
    { label: 'ssup_add_user_xp', value: 'ssup_add_user_xp' },
    { label: 'ssup_add_user_ap', value: 'ssup_add_user_ap' },
    { label: 'ssup_set_user_rank', value: 'ssup_set_user_rank' },
    { label: 'sadvertising_today', value: 'sadvertising_today' },
    { label: 'sPINGping', value: 'sPINGping' },
    { label: 'srmkb', value: 'srmkb' },
    { label: 'srepin', value: 'srepin' },
    { label: 'sunpin', value: 'sunpin' },
    { label: 'sunpinall', value: 'sunpinall' },
    { label: 'sunrepin', value: 'sunrepin' },
    { label: 'stestlog', value: 'stestlog' },
    { label: 'srepreset', value: 'srepreset' },
    { label: 'strcareset', value: 'strcareset' },
    { label: 'strwareset', value: 'strwareset' },
    { label: 'sdellog', value: 'sdellog' },
    { label: 'saddwl', value: 'saddwl' },
    { label: 'sdelwl', value: 'sdelwl' },
    { label: 'sbotaddadmin', value: 'sbotaddadmin' },
    { label: 'sbotdeladmin', value: 'sbotdeladmin' },
    { label: 'sro', value: 'sro' },
    { label: 'sroall', value: 'sroall' },
    { label: 'stestmode', value: 'stestmode' },
    { label: 'stestmodeoff', value: 'stestmodeoff' },
    { label: 'skickall', value: 'skickall' },
    { label: 'skickallnew', value: 'skickallnew' },
    { label: 'skickalloff', value: 'skickalloff' },
    { label: 'ssiteaddadmin', value: 'ssiteaddadmin' },
    { label: 'ssitedeladmin', value: 'ssitedeladmin' },
    { label: 'sreslim', value: 'sreslim' },
    { label: 'ssetconfig', value: 'ssetconfig' },
    { label: 'srevokelink', value: 'srevokelink' },
    { label: 'sdonate', value: 'sdonate' },
    { label: 'sresetrep', value: 'sresetrep' },
    { label: 'sresetfilters', value: 'sresetfilters' },
    { label: 'sresetap', value: 'sresetap' },
    { label: 'sresetxp', value: 'sresetxp' },
    { label: 'sresetrank', value: 'sresetrank' },
    { label: 'sresetallrep', value: 'sresetallrep' },
    { label: 'sresetallfilters', value: 'sresetallfilters' },
    { label: 'sresetallap', value: 'sresetallap' },
    { label: 'sresetallxp', value: 'sresetallxp' },
    { label: 'sresetallrank', value: 'sresetallrank' },
    { label: '-swtf', value: '-swtf' },
    { label: '-saddfilter', value: '-saddfilter' },
    { label: '-sresetfilter', value: '-sresetfilter' },
    { label: '-sdelfilter', value: '-sdelfilter' },
  ]

  gCommandsSelectOptions: Array<SelectOption> = [
    { label: 'gmute', value: 'gmute' },
    { label: 'gmute_media', value: 'gmute_media' },
    { label: 'gmute_inline', value: 'gmute_inline' },
    { label: 'gmute_invite', value: 'gmute_invite' },
    { label: 'gmute_pools', value: 'gmute_pools' },
    { label: 'gban', value: 'gban' },
    { label: 'gkick', value: 'gkick' },
    { label: 'gunmute', value: 'gunmute' },
    { label: 'gunban', value: 'gunban' },
    { label: 'gmsg', value: 'gmsg' },
    { label: 'gpin', value: 'gpin' },
    { label: 'gnotify', value: 'gnotify' },
  ]

  ranksOptions(): Array<SelectOption> {
    if (this.$store.getters.isChatSet) {
      const notUseRankDefaultOption: Array<SelectOption> = [
        { label: this.$t('report_system_min_rank_not_use_option_label').toString(), value: 0 }
      ]

      const ranksModule = ModuleManagerState?.modules.find(m => m instanceof RanksHandlerModule)

      if (ranksModule) {
        return notUseRankDefaultOption
            .concat(
                (ranksModule as RanksHandlerModule).params.user_ranks.map((rank, rankIndex) => ({
                  label: rank.title,
                  value: rankIndex + 1
                }))
            )
      } else {
        return notUseRankDefaultOption
      }
    } else {
      return []
    }
  }

  get entertainmentCallEnabledTypes(): Array<SelectOption> {
    return [
      {
        label: this.$t("entertainment_call_enabled_none"),
        value: "None",
      },
      {
        label: this.$t("entertainment_call_enabled_admins"),
        value: "Admins",
      },
      {
        label: this.$t("entertainment_call_enabled_all"),
        value: "All",
      }
    ]
  }

  emojiOptions: Array<SelectOption> = [
    { label: "👍", value: ReactionEmojiEnum.ThumbsUp },
    { label: "👎", value: ReactionEmojiEnum.ThumbsDown },
    { label: "❤️", value: ReactionEmojiEnum.RedHeart },
    { label: "🔥", value: ReactionEmojiEnum.Fire },
    { label: "🥰", value: ReactionEmojiEnum.SmilingFaceWhitHearts },
    { label: "👏", value: ReactionEmojiEnum.ClappingHands },
    { label: "😁", value: ReactionEmojiEnum.BeamingFaceWithSmilingEyes },
    { label: "🤔", value: ReactionEmojiEnum.ThinkingFace },
    { label: "🤯", value: ReactionEmojiEnum.ExplodingHead },
    { label: "😱", value: ReactionEmojiEnum.FaceScreamingInFear },
    { label: "🤬", value: ReactionEmojiEnum.FaceWithSymbolsOnMouth },
    { label: "😢", value: ReactionEmojiEnum.CryingFace },
    { label: "🎉", value: ReactionEmojiEnum.PartyPopper },
    { label: "🤩", value: ReactionEmojiEnum.StarStruck },
    { label: "🤮", value: ReactionEmojiEnum.FaceVomiting },
    { label: "💩", value: ReactionEmojiEnum.PileOfPoo },
    { label: "🙏", value: ReactionEmojiEnum.FoldedHands },
    { label: "👌", value: ReactionEmojiEnum.OkHand },
    { label: "🕊", value: ReactionEmojiEnum.Dove },
    { label: "🤡", value: ReactionEmojiEnum.ClownFace },
    { label: "🥱", value: ReactionEmojiEnum.HandOnMouth },
    { label: "🥴", value: ReactionEmojiEnum.WoozyFace },
    { label: "😍", value: ReactionEmojiEnum.SmilingFaceWithHeartEyes },
    { label: "🐳", value: ReactionEmojiEnum.SpoutingWhale },
    { label: "❤️‍🔥", value: ReactionEmojiEnum.FlameHeart },
    { label: "🌚", value: ReactionEmojiEnum.NewMoonFace },
    { label: "🌭", value: ReactionEmojiEnum.HotDog },
    { label: "💯", value: ReactionEmojiEnum.HundredPoints },
    { label: "🤣", value: ReactionEmojiEnum.RollingOnTheFloorLaughing },
    { label: "⚡️", value: ReactionEmojiEnum.HighVoltage },
    { label: "🍌", value: ReactionEmojiEnum.Banana },
    { label: "🏆", value: ReactionEmojiEnum.Trophy },
    { label: "💔", value: ReactionEmojiEnum.BrokenHeart },
    { label: "🤨", value: ReactionEmojiEnum.Emoji },
    { label: "😐", value: ReactionEmojiEnum.FaceWithRaisedEyebrow },
    { label: "🍓", value: ReactionEmojiEnum.Strawberry },
    { label: "🍾", value: ReactionEmojiEnum.BottleWithPoppingCork },
    { label: "💋", value: ReactionEmojiEnum.KissMark },
    { label: "🖕", value: ReactionEmojiEnum.MiddleFinger },
    { label: "😈", value: ReactionEmojiEnum.SmilingFaceWithHorns },
    { label: "😴", value: ReactionEmojiEnum.SleepingFace },
    { label: "😭", value: ReactionEmojiEnum.LoudlyCryingFace },
    { label: "🤓", value: ReactionEmojiEnum.NerdFace },
    { label: "👻", value: ReactionEmojiEnum.Ghost },
    { label: "👨‍💻", value: ReactionEmojiEnum.ManTechnologist },
    { label: "👀", value: ReactionEmojiEnum.Eyes },
    { label: "🎃", value: ReactionEmojiEnum.JackOLantern },
    { label: "🙈", value: ReactionEmojiEnum.SeeNoEvilMonkey },
    { label: "😇", value: ReactionEmojiEnum.SmilingFaceWithHalo },
    { label: "😨", value: ReactionEmojiEnum.FearfulFace },
    { label: "🤝", value: ReactionEmojiEnum.Handshake },
    { label: "✍️", value: ReactionEmojiEnum.WritingHand },
    { label: "🤗", value: ReactionEmojiEnum.HuggingFace },
    { label: "🫡", value: ReactionEmojiEnum.Honor },
    { label: "🎅", value: ReactionEmojiEnum.SantaClaus },
    { label: "🎄", value: ReactionEmojiEnum.ChristmasTree },
    { label: "☃️", value: ReactionEmojiEnum.Snowman },
    { label: "💅", value: ReactionEmojiEnum.NailPolish },
    { label: "🤪", value: ReactionEmojiEnum.ZanyFace },
    { label: "🗿", value: ReactionEmojiEnum.Moai },
    { label: "🆒", value: ReactionEmojiEnum.CoolButton },
    { label: "💘", value: ReactionEmojiEnum.HeartWithArrow },
    { label: "🙉", value: ReactionEmojiEnum.HearNoEvilMonkey },
    { label: "🦄", value: ReactionEmojiEnum.Unicorn },
    { label: "😘", value: ReactionEmojiEnum.FaceBlowingKiss },
    { label: "💊", value: ReactionEmojiEnum.Pill },
    { label: "🙊", value: ReactionEmojiEnum.SpeakNoEvilMonkey },
    { label: "😎", value: ReactionEmojiEnum.SmilingFaceWithSunglasses },
    { label: "👾", value: ReactionEmojiEnum.AlienMonster },
    { label: "🤷‍♂️", value: ReactionEmojiEnum.ManShrugging },
    { label: "🤷", value: ReactionEmojiEnum.PersonShrugging },
    { label: "🤷‍♀️", value: ReactionEmojiEnum.WomanShrugging },
    { label: "😡", value: ReactionEmojiEnum.PoutingFace },
  ]

  get messageTypeOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_condition_message_type_text").toString(),
        value: 'Text'
      },
      {
        label: this.$t("trigger_condition_message_type_audio").toString(),
        value: "Audio",
      },
      {
        label: this.$t("trigger_condition_message_type_document").toString(),
        value: "Document",
      },
      {
        label: this.$t("trigger_condition_message_type_sticker").toString(),
        value: "Sticker",
      },
      {
        label: this.$t("trigger_condition_message_type_photo").toString(),
        value: "Photo",
      },
      {
        label: this.$t("trigger_condition_message_type_photo_file").toString(),
        value: "PhotoFile",
      },
      {
        label: this.$t("trigger_condition_message_type_video").toString(),
        value: "Video",
      },
      {
        label: this.$t("trigger_condition_message_type_video_file").toString(),
        value: "VideoFile",
      },
      {
        label: this.$t("trigger_condition_message_type_animation").toString(),
        value: "Animation",
      },
      {
        label: this.$t("trigger_condition_message_type_contact").toString(),
        value: "Contact",
      },
      {
        label: this.$t("trigger_condition_message_type_location").toString(),
        value: "Location",
      },
      /*{
           label: this.$t("trigger_condition_message_type_venue"),
           value.toString(): "Venue",
           },
            */
      {
        label: this.$t("trigger_condition_message_type_voice").toString(),
        value: "Voice",
      },
      {
        label: this.$t("trigger_condition_message_type_game").toString(),
        value: "Game",
      },
      /*{
         label: this.$t("trigger_condition_message_type_invoice"),
         value.toString(): "Invoice",
         },
         */
      {
        label: this.$t("trigger_condition_message_type_video_note").toString(),
        value: "VideoNote",
      },
      /*{
         label: this.$t("trigger_condition_message_type_passport_data"),
         value.toString(): "PassportData",
         },
         */
      {
        label: this.$t("trigger_condition_message_type_poll").toString(),
        value: "Poll",
      }
    ]
  }

  get toxicModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('toxic_filter_mode_option_toxic').toString(),
        value: ToxicFilterModeEnum.Toxic,
      },
      {
        label: this.$t('toxic_filter_mode_option_very_toxic').toString(),
        value: ToxicFilterModeEnum.VeryToxic,
      },
      {
        label: this.$t('toxic_filter_mode_option_risk').toString(),
        value: ToxicFilterModeEnum.Risk,
      },
    ]
  }

  get filterLanguages(): Array<SelectOption> {
    return [
      {
        label: 'Русский/Russian',
        value: 'rus'
      },
      {
        label: 'Английский/English',
        value: 'eng'
      },
      {
        label: 'Арабский/Arabic',
        value: 'ara'
      },
      {
        label: 'Беларусский/Belarusian',
        value: 'bel'
      },
      {
        label: 'Каталанский/Catalan',
        value: 'cat'
      },
      {
        label: 'Хорватский/Croatian',
        value: 'hrv'
      },
      {
        label: 'Чешский/Czech',
        value: 'ces'
      },
      {
        label: 'Нидерландский/Nederlands',
        value: 'nld'
      },
      {
        label: 'Финляндия/Finnish',
        value: 'fin'
      },
      {
        label: 'Французский/French',
        value: 'fra'
      },
      {
        label: 'Немецкий/German',
        value: 'deu'
      },
      {
        label: 'Иврит/Hebrew',
        value: 'heb'
      },
      {
        label: 'Венгерский/Hungarian',
        value: 'hun'
      },
      {
        label: 'Индонезийский/Indonesian',
        value: 'ind'
      },
      {
        label: 'Итальянский/Italian',
        value: 'ita'
      },
      {
        label: 'Казахский/Kazakh',
        value: 'kaz'
      },
      {
        label: 'Корейский/Korean',
        value: 'kor'
      },
      {
        label: 'Малайский/Malay',
        value: 'msa'
      },
      {
        label: 'Норвежский/Norwegian',
        value: 'nor'
      },
      {
        label: 'Перскидский/Persian',
        value: 'fas'
      },
      {
        label: 'Польский/Polish',
        value: 'pol'
      },
      {
        label: 'Португальский/Portuguese',
        value: 'por'
      },
      {
        label: 'Сербский/Serbian',
        value: 'srp'
      },
      {
        label: 'Словацкий/Slovak',
        value: 'slk'
      },
      {
        label: 'Испанский/Spanish',
        value: 'spa'
      },
      {
        label: 'Шведский/Swedish',
        value: 'swe'
      },
      {
        label: 'Турецкий/Turkish',
        value: 'tur'
      },
      {
        label: 'Украинский/Ukrainian',
        value: 'ukr'
      },
      {
        label: 'Узбекский/Uzbek',
        value: 'uzb'
      },
    ]
  }

  get aiDetectPercentOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('ai_detect_percent_75').toString(),
        value: 75
      },
      {
        label: this.$t('ai_detect_percent_85').toString(),
        value: 85
      },
      {
        label: this.$t('ai_detect_percent_90').toString(),
        value: 90
      },
      {
        label: this.$t('ai_detect_percent_95').toString(),
        value: 95
      },
    ]
  }

}
